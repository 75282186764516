/**
 * Product Composable
 */

import type { GalleryProps } from '~/components/mol/Gallery.vue';
import type { PageProductSummaryFragment, Product, ProductPreview, ProductPreviewContent } from '~/graphql/generated';
import type { BikeDetailInfo } from '~/@types/product';

export const useBikeDetail = (product: Partial<Product> | Partial<ProductPreviewContent> | undefined | null) => {
    if (!product || !isProductBike(product)) return;
    const { t, metric, imperial, w, s } = useLocales();
    const getWeigthValue = (product: Partial<Product> | Partial<ProductPreviewContent>) => {
        const metricWeight = product.weight?.metric ? `${product.weight.metric}` : '';
        const imperialWeight = product.weight?.imperial ? `${product.weight.imperial}` : '';
        if (metric && imperial) {
            return `${w(metricWeight)} (${w(imperialWeight, imperial)})`;
        } else if (imperial) {
            return w(imperialWeight, imperial);
        }
        return w(metricWeight);
    };
    const getSizeValue = (product: Partial<Product> | Partial<ProductPreviewContent>) => {
        const metricSize = s([product.min_height?.metric, product.max_height?.metric]);
        const imperialSize = s([product.min_height?.imperial, product.max_height?.imperial], imperial);
        if (metric && imperial) {
            return `${metricSize} (${imperialSize})`;
        } else if (imperial) {
            return imperialSize;
        }
        return metricSize;
    };

    const bikeDetail: BikeDetailInfo = {
        wheels: {
            label: t('bikeDetail.bike'),
            value: `${product.wheels?.toString()}″`,
        },

        weight: {
            label: t('bikeDetail.weight'),
            value: getWeigthValue(product),
        },

        features: {
            label: t('bikeDetail.features'),
            value: product.features || '',
        },

        age: {
            label: t('bikeDetail.age'),
            value: `${product.min_age} - ${product.max_age} ${t('bikeDetail.years')}`,
        },

        size: {
            label: t('bikeDetail.height'),
            value: getSizeValue(product),
        },
    };

    return bikeDetail;
};

export const useProduct = () => {
    const { t, isoLocale } = useLocales();

    const getAvailabilityInfoForVariant = (v: CMSProductVariant | undefined) => {
        if (!v) return t('availability.outOfStock');

        switch (v.availabilityState) {
            case ProductAvailability.END_OF_LIFE: {
                return t('availability.outOfStock');
            }
            case ProductAvailability.OUT_OF_STOCK: {
                if (v.canBeNotified) {
                    return t('availability.notifyMe');
                }
                return t('availability.outOfStock');
            }
            case ProductAvailability.NOT_IN_THIS_REGION: {
                return t('availability.notInThisRegion');
            }
            case ProductAvailability.COMING_SOON: {
                const { availabilityDate } = v;
                let displayDate = '';
                if (availabilityDate) {
                    const diff = Math.floor(dateDayDiff(availabilityDate, new Date()) / 7);
                    if (diff === 0) {
                        displayDate = t('availability.weeks', { period: '1-2' });
                    } else if (diff > 0) {
                        displayDate = t('availability.weeks', { period: `${diff}-${diff + 1}` });
                    }
                }
                if (displayDate === '') {
                    return t('availability.comingsoon.short');
                } else {
                    return t('availability.comingsoon.long', {
                        date: displayDate,
                    });
                }
            }

            case ProductAvailability.PREORDER: {
                const { shippingDate } = v;
                let displayDate = '';
                if (shippingDate) {
                    const now = new Date();
                    const diff = dateDayDiff(shippingDate, now);
                    if (diff > 0 && !isSameDay(shippingDate, now)) {
                        displayDate = new Intl.DateTimeFormat(isoLocale.value).format(addDays(shippingDate, 7));
                    }
                }
                if (displayDate === '') {
                    return t('availability.preorder.short');
                } else {
                    return t('availability.preorder.long', {
                        date: displayDate,
                    });
                }
            }

            case ProductAvailability.AVAILABLE:
                return undefined;
            default:
                return t('availability.outOfStock');
        }
    };

    return {
        getAvailabilityInfoForVariant,
    };
};

export const useUspGallery = (productId: Ref<string | undefined | null>, usp: undefined | WidgetList<ProductUsp>) => {
    return computed(() => {
        if (!productId.value) return [];
        if (!usp) return [];

        return usp.sequence.reduce((accumulator: GalleryProps['data'], id) => {
            const comp = usp.components.find((p) => p.id === id);
            if (comp) {
                accumulator.push({
                    uspTitle: comp.title ?? '',
                    // uspSubtitle: comp.subtitle, // currently not used in gallery
                    uspText: comp.content ?? '',
                    src: getAssetUrl(`${productId.value}-${comp.code}`),
                    productBadge: comp.product_badge,
                    alt: comp.image_alt,
                    video: comp.video,
                });
            }
            return accumulator;
        }, []);
    });
};

export const useColorOption = (config: IConfigurable | undefined) => {
    const colorOption = computed(() => {
        return config?.options?.find((option) => option.type.includes('Color'));
    });
    const selectedColor = computed(() => colorOption.value?.selectedRef.value);
    const hoverColor = ref<string>('');

    const colorOptionChanged = (option: string) => {
        colorOption.value?.setSelected(option);
    };
    const changeHoverColorOption = (option: string) => {
        hoverColor.value = option;
    };
    return {
        colorOption,
        selectedColor,
        hoverColor,
        colorOptionChanged,
        changeHoverColorOption,
    };
};

export const useProductCard = (apiProduct: Partial<Product> | Partial<ProductPreviewContent>, initOptions?: ConfigurableProductInitOptions) => {
    const nuxtApp = useNuxtApp();
    const { ConfigurableProduct } = useEcommerce();
    const { addToCart, cartBusy } = useCart();
    const { isoLocale } = useLocales();

    const addToCartClicked = ref(false);

    const product = processApiProduct(apiProduct, isoLocale.value);
    const bikeDetail = useBikeDetail(apiProduct);
    const config = new ConfigurableProduct(product, initOptions);

    const variant = computed(() => {
        return config.selected.value;
    });

    const { colorOption, selectedColor, hoverColor, colorOptionChanged, changeHoverColorOption } = useColorOption(config);

    const toURL = computed(() => {
        return nuxtApp.$helpers.generateLocalePath(config.variantUrl?.value);
    });

    // TODO: Investigate why validate.limit reached does not update in handleAddToCart
    const handleAddToCart = (componentOrigin?: string) => {
        if (!variant.value) return;

        addToCartClicked.value = true;
        addToCart(
            [
                {
                    product,
                    variant: variant.value,
                    qty: 1,
                    attributes: {
                        _isBike: `${config?.isBike}`,
                    },
                },
            ],
            undefined,
            componentOrigin,
        ).finally(() => {
            addToCartClicked.value = false;
        });
    };

    const addToCartDisabled = computed(() => {
        if (!variant.value) return;
        return (cartBusy.value && !addToCartClicked) || variant.value.availabilityState === ProductAvailability.OUT_OF_STOCK || !config.valid.value;
    });

    const hoverColorVariant = computed(() =>
        hoverColor.value ? product?.variants?.items?.find((variant) => variant.selectedOptions.Color === hoverColor.value) : undefined,
    );

    const isRedCharity = computed(() => {
        if (!variant.value) return false;
        return (variant.value.redCharity && !hoverColorVariant.value) || hoverColorVariant.value?.redCharity;
    });

    const currentImage = computed(() => {
        const sku = hoverColorVariant.value?.sku || variant.value?.sku;
        if (!sku) return;
        return getAssetUrl(`${sku}-side`);
    });

    return {
        addToCartClicked,
        addToCartDisabled,
        bikeDetail,
        cartBusy,
        changeHoverColorOption,
        colorOption,
        colorOptionChanged,
        config,
        currentImage,
        handleAddToCart,
        hoverColor,
        hoverColorVariant,
        isRedCharity,
        product,
        selectedColor,
        toURL,
        variant,
    };
};

export const useProductMicrodata = (productVariant?: CMSProductVariant | undefined, description?: string | null | undefined) => {
    if (!productVariant) return;

    /** https://schema.org/ItemAvailability */
    const getAvailabilityStatus = () => {
        if (productVariant.availabilityState === ProductAvailability.END_OF_LIFE) {
            return 'Discontinued';
        } else if (productVariant.quantityAvailable < 1) {
            return 'OutOfStock';
        } else if (productVariant.quantityAvailable <= ProductQuantityAvailable.LOW_STOCK) {
            return 'LimitedAvailability';
        } else if (productVariant.availabilityState === ProductAvailability.PREORDER) {
            return 'PreOrder';
        }
        return 'InStock';
    };
    const pdpSnippet: {
        '@context': string;
        '@type': string;
        name: string;
        image: string | undefined;
        sku: string;
        description?: string;
        brand?: {
            '@type': string;
            name: string;
        };
        offers: {
            '@type': string;
            price: string | undefined;
            priceCurrency: string;
            availability: string;
        };
    } = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: productVariant.title,
        image: productVariant.shopImage,
        sku: productVariant.sku,
        brand: { '@type': 'Brand', name: 'woom' },
        offers: {
            '@type': 'Offer',
            price: (productVariant.priceNumeric / 100).toFixed(2),
            priceCurrency: productVariant.currency,
            availability: getAvailabilityStatus(),
        },
    };

    if (description) {
        pdpSnippet.description = description;
    }

    useHead({
        script: [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(pdpSnippet),
                key: 'pdp-microdata',
            },
        ],
    });
};

export const useProductSummary = (requestData: Ref<PageProductSummaryFragment | null>) => {
    return {
        apiProduct: computed(() => requestData.value?.page?.product as Partial<Product> | undefined),
        title: computed(() => requestData.value?.page?.base?.title),
        description: computed(() => requestData.value?.page?.base?.description),
        baseUrl: computed(() => requestData.value?.url?.url),
    };
};
